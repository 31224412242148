import React, { useState }  from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import {Helmet} from "react-helmet";
import GaugeChart from 'react-gauge-chart'
import { GatsbyImage } from "gatsby-plugin-image"
import axios from "axios"
import { Button, Modal, Card, Accordion } from "react-bootstrap";
import { TwitterTweetEmbed } from 'react-twitter-embed';

import "../scss/main.scss";

/// Layout
import Header from "../jsx/layouts/nav/Header";
import Footer from "../jsx/layouts/Footer";

export const query = graphql`
  query($slug: String!, $bioguide: String!) {
    biosJson(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      bio {
        birthday
        gender
      }
      ids {
        bioguide
      }
      memberships
      name {
        first
        last
        official_full
        common_full
        nickname
        middle
      }
      social {
        twitter
        twitter_id
        facebook
        instagram
        youtube
        youtube_id
      }
      terms {
        address
        contact_form
        district
        end
        office
        party
        party_code
        phone
        start
        state
        state_name
        state_rank
        type
        url
      }
    }
    imageSharp(fixed: {originalName: {regex: $bioguide}}) {
      gatsbyImageData(width: 225)
    }
    peopleScoresJson(bioguide: {regex: $bioguide}) {
      abc_score
      bioguide
      comments {
        date
        id
        message
        reference
        sentiment
        user
        archived_tweet
      }
      name
      num_neg
      num_pos
      pct_pos
      state
    }
    variablesJson {
      next_term_ends
    }
  }
`





function get_grade_class(grade) {
  return grade == 'NR' ? 'grade-nr text-white text-center d-inline-block'
       : grade == 'F'  ? 'grade-f text-white text-center d-inline-block'
       : grade == 'D'  ? 'grade-d text-white text-center d-inline-block'
       : grade == 'C'  ? 'grade-c text-white text-center d-inline-block'
       : grade == 'C+' ? 'grade-cp text-white text-center d-inline-block'
       : grade == 'B-' ? 'grade-bm text-white text-center d-inline-block'
       : grade == 'B'  ? 'grade-b text-white text-center d-inline-block'
       : grade == 'B+' ? 'grade-bp text-white text-center d-inline-block'
       : grade == 'A-' ? 'grade-am text-white text-center d-inline-block'
       : grade == 'A'  ? 'grade-a text-white text-center d-inline-block'
       : grade == 'A+' ? 'grade-ap text-white text-center d-inline-block'
       : 'grade-nr text-white text-center d-inline-block';
}

export default function Home({ data, pageContext }) {

  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailure, setModalFailure] = useState(false);
  const toggleSuccessModal = () => {
    if (modalSuccess) {
      setModalSuccess(false)
    } else {
      setModalSuccess(true)
    }
  };
  const toggleFailureModal = () => {
    if (modalFailure) {
      setModalFailure(false)
    } else {
      setModalFailure(true)
    }
  };
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    button: "Submit"
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      button: "Submit",
      status: { ok, msg }
    });
    if (ok) {
      setModalSuccess(true)
      form.reset();
    } else {
      setModalFailure(true)
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute('6LeUOaUbAAAAAD2g88K2yL7Hn6hghwUVzORGQGrE', {action: 'submit'}).then(function(token) {
        const form = e.target
        // extract form data
        const form_data = new FormData(form)
        // convert form data to json
        const form_json = {}
        form_data.forEach(function(value, prop){
          form_json[prop] = value
        })
        // add our recaptcha token
        form_json['g-recaptcha-response'] = token
        // convert json to urlencoded query string
        const form_body = Object.keys(form_json).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(form_json[key])).join('&')

        setServerState({ submitting: true, button: "Sending..." });
        axios({
          method: "post",
          url: "https://post.electbitcoin.org/v2/entry/ElectBitcoin/ElectBitcoin-comments/main/comments",
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: form_body
        })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, "Error!", form);
        });
      })
    })
  };



  // build out all of the data transformations we will need on the page
  var last_term = data.biosJson.terms.length - 1
  data['custom'] = {}
  data['custom']['state_breadcrumb_link'] = '/' + data.biosJson.terms[last_term].state_name.replace(/\s+/g, '-').toLowerCase() + '/'
  data['custom']['state_breadcrumb_icon_className'] = "stateface stateface-replace stateface-" + data.biosJson.terms[last_term].state.toLowerCase() + " mr-2"
  data['custom']['office_type'] = (data.biosJson.terms[last_term].type == 'sen') ? "Senator" : (data.biosJson.terms[last_term].type == 'rep') ? "Representative" : "Unknown"
  data['custom']['his_her'] = (data.biosJson.bio.gender == 'M') ? "his" : (data.biosJson.bio.gender == 'F') ? "her" : "their"
  // handle district 0 reps
  if (data.biosJson.terms[last_term].type == 'rep' && data.biosJson.terms[last_term].district == 0) {
    data['custom']['office_subtype'] = ''
  } else {
  // handle non-district 0
    data['custom']['office_subtype'] = (data.biosJson.terms[last_term].type == 'sen') ? data.biosJson.terms[last_term].state_rank[0].toUpperCase() + data.biosJson.terms[last_term].state_rank.substring(1) : (data.biosJson.terms[last_term].type == 'rep') ? 'District ' + data.biosJson.terms[last_term].district : "Unknown"
  }
  
  data['custom']['state_profile_icon_className'] = "stateface stateface-replace stateface-" + data.biosJson.terms[last_term].state.toLowerCase()

  data['custom']['phone_link'] = 'tel:' + data.biosJson.terms[last_term].phone

  data['custom']['person_score_class'] = get_grade_class(data.peopleScoresJson.abc_score)
  data['custom']['gauge_position'] = Number((data.peopleScoresJson.abc_score == "NR") ? parseFloat('0.50') : parseFloat(data.peopleScoresJson.pct_pos))

  var num_pages = pageContext.num_pages
  data['custom']['pagination_array'] = Array.from(Array(num_pages).keys())

  // build arrays of positive, negative, and neutral grades
  data['custom']['positive_grades'] = ['A+', 'A', 'A-', 'B+', 'B', 'B-']
  data['custom']['neutral_grades'] = ['C+', 'C', 'NR']
  data['custom']['negative_grades'] = ['D', 'F']

  // build an array of social buttons
  data['custom']['social_buttons'] = []
  if (data.biosJson.social.twitter != null) {
    data['custom']['twitter_link'] = 'https://twitter.com/' + data.biosJson.social.twitter
    data['custom']['twitter_className'] = "btn btn-twitter rounded mb-2 ml-1 mr-1"
    data['custom']['twitter_userName'] = '@' + data.biosJson.social.twitter + " "
    data['custom']['twitter_iconClassName'] = "fab fa-twitter fa-lg"
  }
  if (data.biosJson.social.instagram != null) {
    let button = {}
    button['link'] = 'https://instagram.com/' + data.biosJson.social.instagram
    button['className'] = "btn btn-instagram rounded mb-4 width80 ml-1 mr-1"
    button['userName'] = '@' + data.biosJson.social.instagram + " "
    button['iconClassName'] = "fab fa-instagram fa-2x"
    data['custom']['social_buttons'].push(button)
  }
  if (data.biosJson.social.facebook !== null) {
    let button = {}
    button['link'] = 'https://facebook.com/' + data.biosJson.social.facebook
    button['className'] = "btn btn-facebook rounded width80 mb-4 ml-1 mr-1"
    button['userName'] = data.biosJson.social.facebook + " "
    button['iconClassName'] = "fab fa-facebook fa-2x"
    data['custom']['social_buttons'].push(button)
  }
  if (data.biosJson.social.youtube !== null) {
    let button = {}
    button['link'] = 'https://youtube.com/user/' + data.biosJson.social.youtube
    button['className'] = "btn btn-youtube rounded width80 mb-4 ml-1 mr-1"
    button['userName'] = data.biosJson.social.youtube + " "
    button['iconClassName'] = "fab fa-youtube fa-2x"
    data['custom']['social_buttons'].push(button)
  }

  // console.log(data)
  // console.log(pageContext)
  return (
    <>
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width,initial-scale=1"/>
        <script defer data-domain="electbitcoin.org" src="https://data.electbitcoin.org/js/script.js"></script>
        <title>ElectBitcoin.org - {data.custom.office_type} {data.biosJson.name.common_full} of {data.biosJson.terms[last_term].state}</title>
        <script src='https://www.recaptcha.net/recaptcha/api.js?render=6LeUOaUbAAAAAD2g88K2yL7Hn6hghwUVzORGQGrE'></script>
      </Helmet>
      <Header />
      <div className="content-body">
        <div className="container-fluid pt-2">
          <div class="text-center pb-2">
            <a href="https://twitter.com/ElectBitcoinOrg" target="_blank" class="badge badge-info badge-rounded">
              <i class="lab la-twitter scale3"></i> Follow @ElectBitcoinOrg for updates when new records are added!
            </a>
          </div>
          <div className="form-head page-titles d-flex  align-items-center">
            <div className="mr-auto  d-lg-block">
              <h2 className="text-black font-w600 d-inline-flex mr-4 ml-2">{data.biosJson.name.common_full}</h2>
              <ol className="breadcrumb d-inline-flex">
                <li className="breadcrumb-item active" key={data.custom.state_breadcrumb_link}>
                  <Link to={data.custom.state_breadcrumb_link}><span className={data.custom.state_breadcrumb_icon_className}>{data.biosJson.terms[last_term].state}</span>{data.biosJson.terms[last_term].state_name}</Link>
                </li>
                <li className="breadcrumb-item" key={data.custom.office_subtype}>
                  {data.custom.office_subtype} {data.custom.office_type}
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-xxl-4">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card text-center">
                    <div className="card-body">
                      <h2 className="fs-28 text-black">ElectBitcoin Grade: <span className={data.custom.person_score_class}>{data.peopleScoresJson.abc_score}</span></h2>
                      <GaugeChart id="score-gauge-chart"
                        nrOfLevels={10}
                        colors={["#ff6746", "#ff7f3d", "#fe9933", "#feb428", "#fdcb1f", "#fddf17", "#d0dc26", "#9dd837", "#6ad548", "#37d159"]}
                        arcPadding={0.05}
                        arcWidth={0.25}
                        percent={`${data.custom.gauge_position}`}
                        hideText={true}
                        cornerRadius={2}
                        animDelay={0}
                      />
                      <h3 className="fs-25 text-black">Items On Record:</h3>
                      <span className="text-success text-center mr-4">{data.peopleScoresJson.num_pos} Positive <i className="las la-thumbs-up scale5"></i></span>
                      <span className="text-danger text-center">{data.peopleScoresJson.num_neg} Negative <i className="las la-thumbs-down scale5"></i></span>
                      <div className="card-footer border-0 p-0 mb-0 mt-2">
                        <Link to="/about/#grades" className="text-primary text-center fs-13"><i className="fas fa-info-circle"></i> Learn About Our Grades</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <div className="card text-center">
                    <div className="card-body">
                      <div className="position-relative mb-3 d-inline-block">
                        <GatsbyImage image={data.imageSharp.gatsbyImageData} alt={data.biosJson.name.common_full} className="rounded" width={225}/>
                        <span className="profile-icon">
                            <span className={data.custom.state_profile_icon_className}>{data.biosJson.terms[last_term].state.toUpperCase()}</span>
                        </span>
                      </div>
                      <h4 className="text-black fs-22 font-w600">
                        {data.biosJson.name.common_full} ({data.biosJson.terms[last_term].party_code})
                      </h4>
                      <span className="mb-3 text-black d-block fs-16 font-w500">{data.biosJson.terms[last_term].state_name}</span>
                      <span className="mb-3 text-black d-block">{data.custom.office_subtype} {data.custom.office_type}</span>
                      {data.biosJson.social.twitter != null &&
                        <>
                          <a href={data.custom.twitter_link} target="_blank">
                            <button type="button" className={data.custom.twitter_className}>
                              {data.custom.twitter_userName}<span className="btn-icon-right"><i className={data.custom.twitter_iconClassName}></i></span>
                            </button>
                          </a>
                          <div className="mb-3">
                            <a href={"https://twitter.com/search?q=(from%3A" + data.biosJson.social.twitter + ")%20(*bitcoin*%20OR%20*cryptocurrency*%20OR%20*crypto*%20OR%20*cryptocurrencies*%20OR%20*blockchain*)&src=typed_query&f=live"} className="text-primary text-center fs-13" target="_blank"><i className="fas fa-search"></i> Search {data.custom.office_type} {data.biosJson.name.last}'s Tweets</a>
                          </div>
                        </>
                      }
                      <div>
                        {data.custom.social_buttons.map((social_button) => (
                          <a href={social_button.link} target="_blank" key={social_button.link}>
                            <button type="button" className={social_button.className}>
                              <i className={social_button.iconClassName}></i>
                            </button>
                          </a>
                        ))}
                      </div>
                      <hr/>
                      <span className="mb-3 text-black d-block font-w600">Office:</span>
                      <p>
                        {data.biosJson.terms[last_term].address}
                      </p>
                      <div>
                        <a href={data.custom.phone_link}>
                          <button type="button" className="btn btn-outline-primary rounded mb-4">
                            <i className="las la-phone scale5 mr-2"></i>{data.biosJson.terms[last_term].phone}
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href={data.biosJson.terms[last_term].contact_form} target="_blank">
                          <button type="button" className="btn btn-primary rounded mb-4">
                            <i className="las la-envelope-open scale5 mr-2"></i>Email {data.custom.office_type} {data.biosJson.name.last}
                          </button>
                        </a>
                      </div>
                      <hr/>
                      <span className="mb-3 text-black d-block font-w600">Memberships:</span>
                      <ul className="list-icons text-sm-left fs-14">
                        {data.biosJson.memberships.map((membership) => (
                          <li className="mb-2" key={membership}>
                            <span className="align-middle mr-2"><i className="fa fa-chevron-right"></i></span>
                            {membership}
                          </li>
                        ))}
                      </ul>
                      <hr/>
                      <span className="mb-3 text-black d-block font-w600">Terms:</span>
                      <ul className="list-icons text-sm-left fs-14">
                        {data.biosJson.terms.slice(0).reverse().map((term) => (
                          <li className="mb-2" key={term.start}>
                            <span className="align-middle mr-2"><i className="fa fa-chevron-right"></i></span>
                            <strong>{term.start.split('-')[0]} to {term.end.split('-')[0] + ' - '}</strong>
                            {term.state} {term.type == "sen" && 'Senator'} {term.type == "rep" && 'District ' + term.district + ' Representative'}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-xxl-8">
              {data.custom.positive_grades.includes(data.peopleScoresJson.abc_score) && data.variablesJson.next_term_ends == data.biosJson.terms[last_term].end &&
                <>
                  <div role="alert" class="fade solid alert-rounded alert alert-success show">
                    <i class="las la-bullhorn scale5 mr-2">!</i><strong>Seat Up For Reelection in {parseInt(data.variablesJson.next_term_ends.split('-')[0])-1}!</strong> Thank you {data.biosJson.terms[last_term].type.charAt(0).toUpperCase()
  + data.biosJson.terms[last_term].type.slice(1)}. {data.biosJson.name.last} for your support! Everyone, please vote!
                  </div>
                </>
              }
              {data.custom.neutral_grades.includes(data.peopleScoresJson.abc_score) && data.variablesJson.next_term_ends == data.biosJson.terms[last_term].end &&
                <>
                  <div role="alert" class="fade solid alert-rounded alert alert-dark show">
                    <i class="las la-bullhorn scale5 mr-2">!</i><strong>Seat Up For Reelection in {parseInt(data.variablesJson.next_term_ends.split('-')[0])-1}!</strong> Vote for candidates that support American innovators in finance and technology!
                  </div>
                </>
              }
              {data.custom.negative_grades.includes(data.peopleScoresJson.abc_score) && data.variablesJson.next_term_ends == data.biosJson.terms[last_term].end &&
                <>
                  <div role="alert" class="fade solid alert-rounded alert alert-danger show">
                    <i class="las la-bullhorn scale5 mr-2">!</i><strong>Seat Up For Reelection in {parseInt(data.variablesJson.next_term_ends.split('-')[0])-1}!</strong> Vote for candidates that support American innovators in finance and technology!
                  </div>
                </>
              }
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Add An Item To {data.biosJson.name.common_full}'s Record</h4>
                    </div>
                    <Modal className="fade" show={modalSuccess} onHide={toggleSuccessModal}>
                      <Modal.Header>
                        <Modal.Title className="text-success">Thank You!</Modal.Title>
                        <Button
                          onClick={() => setModalSuccess(false)}
                          variant=""
                          className="close"
                        >
                          <span>&times;</span>
                        </Button>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          <strong>Your submission to {data.biosJson.name.common_full}'s Record was sent successfully!</strong> 
                        </p>
                        <p>
                          Our moderators review most submissions within 24 hours or less. You can track your submission on our <a href="https://github.com/ElectBitcoin/ElectBitcoin/issues?q=is%3Aissue" target="_blank" className="text-primary">GitHub Issue List</a> (it may take up to 5 minutes for the Issue to be created).
                        </p>
                        <p>
                          If you have questions about your submission or need to get in touch, <a href="https://github.com/ElectBitcoin/ElectBitcoin/discussions/categories/general" target="_blank" className="text-primary">start a discussion on our GitHub.</a>
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button className="rounded" variant="primary" onClick={() => setModalSuccess(false)}>OK</Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal className="fade" show={modalFailure} onHide={toggleFailureModal}>
                      <Modal.Header>
                        <Modal.Title className="text-danger">Uh Oh!</Modal.Title>
                        <Button
                          onClick={() => setModalFailure(false)}
                          variant=""
                          className="close"
                        >
                          <span>&times;</span>
                        </Button>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          <strong>Your submission to {data.biosJson.name.common_full}'s Record FAILED!</strong>
                        </p>
                        <p>
                          If this happens more than once, please alert our team by <a href="https://github.com/ElectBitcoin/ElectBitcoin/discussions/categories/support" target="_blank" className="text-primary">starting a discussion on our GitHub.</a>
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button className="rounded" variant="primary" onClick={() => setModalFailure(false)}>OK</Button>
                      </Modal.Footer>
                    </Modal>
                    <div className="card-body">
                      <div className="basic-form">
                        <form className="form-validate-record-submit" id="form-record-submit" onSubmit={handleOnSubmit}>
                          <div className="form-group mb-0">
                            <div className="record-sentiment mb-1">
                              <div>
                                <p>Is your submission Positive or Negative towards Bitcoin, Cryptocurrencies, and Blockchain Technology?</p>
                              </div>
                              <input type="radio" name="fields[record-sentiment]" value="positive" id="positive" required="required"/><label htmlFor="positive" id="positive" className="btn btn-outline-success rounded"> Positive <i className="las la-thumbs-up scale5 mr-2"></i></label>
                              <input type="radio" name="fields[record-sentiment]" value="negative" id="negative"/><label htmlFor="negative" id="negative" className="btn btn-outline-danger rounded"> Negative <i className="las la-thumbs-down scale5 mr-2"></i></label>

                              <div className="record-sentiment-confirmation-positive">
                                Let's Thank {data.biosJson.name.first}!
                              </div>
                              <div className="record-sentiment-confirmation-negative">
                                Let's encourage {data.biosJson.name.first} to support U.S. innovators in Finance & Technology.
                              </div>

                              <input name="fields[politician_name]" type="hidden" value={data.biosJson.name.common_full}></input>
                              <input name="fields[politician_state]" type="hidden" value={data.biosJson.terms[last_term].state}></input>
                              <input name="fields[bioguide]" type="hidden" value={data.biosJson.ids.bioguide}></input>
                              <input type="hidden" name="options[reCaptcha][siteKey]" value="6LeUOaUbAAAAAD2g88K2yL7Hn6hghwUVzORGQGrE"></input>
                              <input type="hidden" name="options[reCaptcha][secret]" value="C7yRMF3juNqoDn05/yTw0Zp2TP/VEiOVjhUxepQwuQhodVNfB82VeB8hrzJNxR3rHxLAvIIQ7qa/RlvHJu4143A7xOCjiippiCE1gSYFhasptL3q8cRnoIhA7LMxuMcaWQdbPflb8f2tB5i3ynlBj0dBWNNnjvvsm72dRchmv8dXwGCRQ5R4OiKBgotACmZoJiVGE5ivMtY/G0GZYPfD3ExFApcE7BEXtZ3x/8K7IwRYE3G611EPxRPeu3YlB+vUkpX2ZkW1w3E9AxSU2tUZvYiO7ST5MqNMnO6XdzUud/1OALqkjW6HW5P/e7Ev73qfMbx9vXcYzaDwxNlWUVmW7xMveKnMYPbt/TSedKBp2Amf9mlJ1l10Kc4NqxJK8oGwupTPIyhtlbv63fXvjQDbIQGQK8/Ydp2kTsXkl2E+PT8sx7vhZHpT3QNJv0pOcE88z96MzJlLekIEC5mttwPbwf1ylSSU9pA2+b5wkNis7urkULIoFjQXM7/CUrGIk7PykRDceN205+Glzp01aDRDJp5z8PQPu3BdIGJV9bzxoPXO1vF6frohzOhjmi9/xZ281MLcXsx8enztJt2Dlh+MJO8j22xGtt+SZS0//ldg2zQuPck35kVuQN+l/BLj6IM/W5xOBjS+ymInXeGHeVk+Wc976L6BG4/9ePDao+FOWbo="></input>
                              
                              <div className="input-group record-sentiment-forms row ml-0 mr-0">
                                <div className="input-group-prepend">
                                  <span className="input-group-text rounded-left"> <i className="fa fa-user"></i> </span>
                                </div>
                                <input type="text" className="form-control mr-1 rounded-right" id="val-username1" name="fields[username]" placeholder="Name (Optional)"/>
                                <div className="record-sentiment-form-guide ml-1">If provided, your name will be published with your submission, otherwise "Anonymous" will be used.</div>
                              </div>
                              <div className="input-group record-sentiment-forms row ml-0 mr-0">
                                <div className="input-group-prepend">
                                  <span className="input-group-text rounded-left"> <i className="fa fa-comments"></i> </span>
                                </div>
                                <textarea className="form-control rounded-right" name="fields[record]" form="form-record-submit" placeholder="Record Item Content (Required *unless submitting only a tweet*): All submitted record items MUST be fact-based and free of all opinions. Direct quotes, sponsored bills, and vote history are all examples of great content. Moderators will only approve record items that meet these guidelines. Check approved record items below and on other politicians to see what makes a great submission." rows="6"></textarea>
                                <div className="record-sentiment-form-guide ml-1 text-info"><i class="lab la-twitter scale3"></i>If you are only submitting a tweet that does not require additional context, you can skip the above box and simply paste the tweet URL in the reference box below:</div>
                              </div>
                              <div className="input-group record-sentiment-forms row ml-0 mr-0">
                                <div className="input-group-prepend">
                                  <span className="input-group-text rounded-left"> <i className="fa fa-link"></i> </span>
                                </div>
                                <input type="url" className="form-control rounded-right" id="val-reference1" name="fields[reference]" placeholder="Reference Link (Required): MUST be to an official source or trustworthy news publication." required/>
                              </div>
                              <div className="record-sentiment-forms">
                                <button type="submit" className="btn mr-0 btn-primary rounded float-right" disabled={serverState.submitting}>{serverState.button}</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-xl-12" id="records">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">{data.biosJson.name.common_full}'s Record on Bitcoin, Cryptocurrencies, and Blockchain</h4>
                    </div>
                    <div className="card-body">
                      <div id="record-timeline" className="widget-timeline">
                        <ul className="timeline">
                          {pageContext.comments.map((comment) => (
                            <li key={comment.date}>
                              {comment.sentiment == 'positive' &&
                                <div className="timeline-badge pos"></div>
                              }
                              {comment.sentiment == 'negative' &&
                                <div className="timeline-badge neg"></div>
                              }
                              <span className="timeline-panel text-muted">
                                <span>{new Date(comment.date).toLocaleString("en-US")}</span>
                                {comment.sentiment == 'positive' && 
                                  <h6 className="text-success">Positive. +1  <i className="las la-thumbs-up scale5 mr-2"></i></h6>
                                }
                                {comment.sentiment == 'negative' && 
                                  <h6 className="text-danger">Negative. +1  <i className="las la-thumbs-down scale5 mr-2"></i></h6>
                                }
                                {comment.message != '' &&
                                  <p style={{"whiteSpace": "pre-line"}}>{comment.message}</p>
                                }
                                {comment.message == '' && "archived_tweet" in comment && comment.archived_tweet != null &&
                                  <p style={{"whiteSpace": "pre-line"}}>{data.custom.office_type} {data.biosJson.name.last} Tweeted:</p>
                                }
                                {"archived_tweet" in comment && comment.archived_tweet != null &&
                                  <Accordion className='accordion'>
                                      <div className='accordion__item' key="0">
                                        <Accordion.Toggle as={Card.Text} eventKey="0" className='accordion__header--info mb-0' >
                                          <span className='accordion__header--text text-info'><i class="lab la-twitter scale3"></i>Click for Tweet Screenshot In Case of Deletion or Edits</span>
                                          <span className='accordion__header--indicator style_two'></span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                          <div className='accordion__body--text'>
                                            <img src={'/tweet_archive/' + comment.archived_tweet} alt="Screenshot of Archived Tweet" />
                                          </div>
                                        </Accordion.Collapse>
                                      </div>
                                  </Accordion>
                                }
                                {/* Check for youtube link */}
                                { /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/.test(comment.reference) && 
                                  <div className="text-center embed-responsive embed-responsive-16by9 mb-4">
                                    <iframe src={comment.reference.replace(/(watch\?v=)/, 'embed/').replace(/[&|?]t=(\d+)s/, '?start=$1&rel=0').replace('https://youtu.be/', 'https://youtube.com/embed/')}></iframe>
                                  </div>
                                }
                                {/* Check for twitter link */}
                                { /^http(s)?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/.test(comment.reference) && 
                                  <div className="mb-4">
                                    <TwitterTweetEmbed tweetId={comment.reference.match(/^http(s)?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/)[4]}/>
                                  </div>
                                }
                                {/* Check for x.com link */}
                                { /^http(s)?:\/\/x\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/.test(comment.reference) && 
                                  <div className="mb-4">
                                    <TwitterTweetEmbed tweetId={comment.reference.match(/^http(s)?:\/\/x\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/)[4]}/>
                                  </div>
                                }
                                {comment.sentiment == 'positive' &&
                                  <a className="mb-0 text-success" href={comment.reference} target="_blank"><i className="fa fa-link"></i> Reference Link</a>
                                }
                                {comment.sentiment == 'negative' &&
                                  <a className="mb-0 text-danger" href={comment.reference} target="_blank"><i className="fa fa-link"></i> Reference Link</a>
                                }
                                {comment.user != "" &&
                                  <span className="float-right">Submitted By: {comment.user}</span>
                                }
                                {comment.user == "" &&
                                  <span className="float-right">Submitted By: Anonymous</span>
                                }
                              </span>
                            </li>
                          ))}
                        </ul>
                        {pageContext.comments.length == 0 &&
                          <div className="text-center mb-3">
                            <i className="fa fa-comments scale5 fs-46 text-dark mb-4 mt-4"></i>
                            <h4 className="text-dark">
                              {data.custom.office_type} {data.biosJson.name.common_full} does not yet have any items on {data.custom.his_her} ElectBitcoin.org Record and therefore has a <strong>Grade of 'NR' (Not Rated)</strong>. 
                            </h4>
                            <hr className="mt-5 mb-5"/>
                            <p>
                              Has {data.biosJson.name.common_full} ever commented publicly, introduced or authored legislation, or voted on issues relating to <strong>Bitcoin, Cryptocurrencies, & Blockchain Technology</strong>?
                            </p>
                            <p>
                              If so, submit a <span className="text-success">Positive <i className="las la-thumbs-up scale2"></i></span> or <span className="text-danger">Negative <i className="las la-thumbs-down scale2"></i></span> item now, using the buttons above, to create the first entry in {data.custom.his_her} record!
                            </p>
                          </div>
                        }
                        {pageContext.num_pages > 1 &&
                          <nav>
                            <ul className="pagination pagination-xs pagination-gutter pagination-primary no-bg justify-content-center pt-2 mb-0">
                              {pageContext.current_page == 2 &&
                                <li className="page-item rounded-sm page-indicator" key={'left' + pageContext.slug + '/'}>
                                  <Link className="page-link" to={pageContext.slug + '/'}><i className="la la-angle-left"></i></Link>
                                </li>
                              }
                              {pageContext.current_page > 2 &&
                                <li className="page-item rounded-sm page-indicator" key={'left' + pageContext.slug + '/' + (pageContext.current_page - 1)}>
                                  <Link className="page-link" to={pageContext.slug + '/' + (pageContext.current_page - 1) + '/'}><i className="la la-angle-left"></i></Link>
                                </li>
                              }
                              {data['custom']['pagination_array'].map((page) => (
                                <div>
                                  {pageContext.current_page == (page+1) &&
                                    <>
                                      {page == 0 &&
                                        <li className="page-item rounded-sm active" key={'zero' + pageContext.slug + '/'}>
                                          <Link className="page-link" to={pageContext.slug + '/'}>{page + 1}</Link>
                                        </li>
                                      }
                                      {page != 0 &&
                                        <li className="page-item rounded-sm active" key={'notzero' + pageContext.slug + '/' + (page + 1)}>
                                          <Link className="page-link" to={pageContext.slug + '/' + (page + 1) + '/'}>{page + 1}</Link>
                                        </li>
                                      }
                                    </>
                                  }
                                  {pageContext.current_page != (page+1) &&
                                    <>
                                      {page == 0 &&
                                        <li className="page-item" key={'num' + pageContext.slug + '/'}>
                                          <Link className="page-link" to={pageContext.slug + '/'}>{page + 1}</Link>
                                        </li>
                                      }
                                      {page != 0 &&
                                        <li className="page-item" key={'num' + pageContext.slug + '/' + (page + 1)}>
                                          <Link className="page-link" to={pageContext.slug + '/' + (page + 1) + '/'}>{page + 1}</Link>
                                        </li>
                                      }
                                    </>
                                  }
                                </div>
                              ))}
                              {pageContext.current_page < (data['custom']['pagination_array'].length) &&
                                <li className="page-item page-indicator" key={'right' + pageContext.slug + '/' + (pageContext.current_page + 1)}>
                                  <Link className="page-link" to={pageContext.slug + '/' + (pageContext.current_page + 1) + '/'}><i className="la la-angle-right"></i></Link>
                                </li>
                              }
                            </ul>
                          </nav>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
